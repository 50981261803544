export enum ExternalURL {
  discord,
  twitter,
  docs,
}

export const externalURL = (externalURL: ExternalURL) => {
  switch (externalURL) {
    case ExternalURL.discord:
      return 'https://discord.gg/bkzmXMCEGt';
    case ExternalURL.twitter:
      return 'https://twitter.com/nounsdao';
    case ExternalURL.docs:
      return 'https://nouns.notion.site/Explore-Nouns-a2a9dceeb1d54e10b9cbf3f931c2266f';

  }
};
