import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';

const Documentation = () => {
  return (
    <Section fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper}>
          <h1>Angel DAO  Manifesto</h1>
          <p>
          The marriage between capitalism and democracy, as well as the brotherhood between capital and innovation, are coming to an end. 

Now, capital is only for the people with relationships and the banks that are too big to fail. The money for innovation comes with a prerequisite - kneeling down and giving up the revolutionary spirit. Silicon Valley and Wallet street failed us, and it is time to form a new league.  

We, Angel DAO, are forming to give back the capital power to the people. We are driving capitalism to its final form and to the end of its life cycle. It is the natural right of each individual to access the free market without worrying about crossing the border of nation states, to contribute directly in the global financial system without any middleman, to be empowered for the brilliant ideas, regardless of the location, gender or race.

          </p>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Summary</Accordion.Header>
            <Accordion.Body>
              <p>We are trying to build an evergreen dao that is backed by enormous amount of fund in treasury to support innovators in web3.0 space</p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Daily Auctions</Accordion.Header>
            <Accordion.Body>
              <p>
                The Auction Contract will act as a self-sufficient generation and
                distribution mechanism, auctioning one noun every 24 hours, forever. 100% of auction
                proceeds (ETH) are automatically deposited in the Angel Dao treasury, where they are
                governed by noun owners.
              </p>

              <p>
                Each time an auction is settled, the settlement transaction will also cause a new
                noun to be minted and a new 24 hour auction to begin.{' '}
              </p>
              <p>
                While settlement is most heavily incentivized for the winning bidder, it can be
                triggered by anyone, allowing the system to trustlessly auction nouns as long as
                Ethereum is operational and there are interested bidders.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Core Assets</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>Treasury Assets</li>
                <li>Angel NFT</li>
                <li>EXP/ANG token ERC-20 token</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Governance ‘Slow Start’
            </Accordion.Header>
            <Accordion.Body>
              <p>
                In addition to the precautions taken by Compound Governance, Nounders have given
                themselves a special veto right to ensure that no malicious proposals can be passed
                while the noun supply is low. This veto right will only be used if an obviously
                harmful governance proposal has been passed, and is intended as a last resort.
              </p>
              <p>
                Nounders will proveably revoke this veto right when they deem it safe to do so. This
                decision will be based on a healthy noun distribution and a community that is
                engaged in the governance process.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Section>
  );
};
export default Documentation;
